import { TemplateQuestionPanelState, Tab, TabType, DeepQuestionnaire } from '@dims/components';

const customerSpecificationTabs: Tab[] = [{
  title: 'Basis',
  section: 0,
  panelState: TemplateQuestionPanelState.INIT,
},
{
  title: 'Puljekøb',
  section: 1,
  panelState: TemplateQuestionPanelState.INIT,
},
{
  title: 'Vilkår',
  section: 2,
  panelState: TemplateQuestionPanelState.INIT,
},
];

function supplierOfferTabs(p: number) {
  return [
    {
      title: 'Afgiv priser',
      section: p,
      panelState: TemplateQuestionPanelState.INIT,
    },
  ];
}

const priceSectionId = '3d6109c4-b01f-4ef2-97d4-a1058e6840fc'; // Tilbudsliste
let supplierOfferTabNumber = 3;

export function setSupplierSpecificationTabs(_: DeepQuestionnaire|null):number[] {
  return [];
}

export function setSupplierPriceTab(offerQuestionnaire: DeepQuestionnaire|null):number {
  supplierOfferTabNumber = offerQuestionnaire?.questions.findIndex((q) => q.questionId === priceSectionId) ?? 3;
  return supplierOfferTabNumber;
}

const questionnaireTabConfig = {
  getTabs: (type: TabType): Tab[] => {
    switch (type) {
      case 'CustomerSpecification':
        return customerSpecificationTabs;
      case 'SupplierSpecification':
        return [];
      case 'SupplierOffer':
        return supplierOfferTabs(supplierOfferTabNumber);
      default:
        return [];
    }
  },
};

export default questionnaireTabConfig;
