// This file contains configuration of the "Opsummering" view for the supplier.
// It lists question id's of questions on the second level in the questionnaire template
// to be excluded from the "Opsummering" view. Questions not in this list will be
// shown in the "Opsummering" view.

const excludedQuestions = [
  'b464d5a0-a3ae-47ee-8576-5f69da92308e', // Behov
  '81c28a1c-a9cc-4b39-bee1-d9930cdc7a3d', // Evaluering
];

export default excludedQuestions;
