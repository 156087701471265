<template>
  <EvaluationOfferListBase
    :tender="tender"
    :offers="offers"
    :offersEvaluations="offersEvaluations"
    :additionalHeaders="[]"
    :isLoadingOffers="isLoadingOffers"
    contractSumTooltip="Den samlet tilbudte pris inkl. optioner"
    @fetchOffers="fetchOffers"
  >
    <template #additional-columns></template>
    <template #tco></template>
    <template #bpq="{ offer, index }">
      <BpqDocumentationDialog
        :offer="offer"
        :offerEvaluation="getOfferEvaluation(offer.id)"
        :tender="tender"
        :conditionalOffers="conditionalOffers"
        :index="index"
      />
    </template>
  </EvaluationOfferListBase>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import {
  EvaluationOfferListBase,
  OfferEvaluation,
} from '@dims/components';
import BpqDocumentationDialog from './BpqDocumentationDialog.vue';
import { Offer5086, Tender5086 } from '@/models';
import { getCalculator } from '@/services';

const emit = defineEmits<{
  fetchOffers: []
  }>();
const { tender, offers, isLoadingOffers = false, offersEvaluations } = defineProps<{
  tender: Tender5086,
  offers: Offer5086[],
  offersEvaluations: OfferEvaluation[],
  isLoadingOffers?: boolean,
}>();
const calculator = getCalculator();

function getOfferEvaluation(offerId: string) {
  return offersEvaluations.find((oe) => oe.offerId === offerId);
}

function fetchOffers() {
  emit('fetchOffers');
}

const conditionalOffers = computed((): Offer5086[] => {
  // Get offers to be shown in list of conditional offers
  const ofs = offers.filter(
    (o) => o.status !== 'Unconditional'
        && !(o.isUnconditional ?? false)
        && !calculator.isScoreBelowConditionalThreshold_BackendCalculation(
          o,
        )
        && !calculator.isScoreBelowConditionalThreshold_FrontendCalculation(
          o,
          tender,
        ),
  );
  console.info('get conditional offers returns: ', ofs);
  if (tender.awardCriteriaType === 'TCO') {
    return ofs;
  }
  return calculator.sortBPQBestFirst(ofs, tender);
});

</script>
