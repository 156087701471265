import { AwardCriteriaType, Supplier, TenderSummary } from '@dims/components';
import { Offer5086 } from '@/models/Offer';
import { awardCriteriaType } from '@/services';
import { TenderData5086 } from '@/models';

// TODO: What filters are appropriate for IT?
export default class FilterConfiguration {
  awardTypeFilter = '';

  awardTypeLabels = (['TCO', 'BPQRatio'] as const).map((a) => awardCriteriaType.getLabel(a));

  properties = [
    { label: 'Tildelingskriterie', propertyName: 'awardTypeFilter', options: this.awardTypeLabels },
  ];

  filterTender(tender: TenderSummary) {
    return this.filter(tender.awardCriteriaType);
  }
  filterOffer(offer: Offer5086) {
    return this.filter(offer.tenderData.awardCriteriaType);
  }
  filter(awardType?: AwardCriteriaType) {
    return (
      this.awardTypeFilter === ''
          || ((awardType !== undefined)
            && awardCriteriaType.getLabel(awardType) === this.awardTypeFilter));
  }
  filterLot(l: { lotNo:string, title: string }) {
    return (
      this.awardTypeFilter === ''
          || this.awardTypeFilter.includes(l.lotNo)
    );
  }
  filterSupplier(_tenderData: TenderData5086, _supplier: Supplier) {
    return true;
  }
}
